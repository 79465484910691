
<template>
  <el-table
    ref="multipleTable"
    :data="data"
    tooltip-effect="dark"
    style="width: 100%"
    class="wom"
    border
    @selection-change="handleSelectionChange"
  >
    <el-table-column v-if="isShowSele" type="selection" width="55"
    >
    </el-table-column>

    <template v-for="(item, index) in list">
      <el-table-column
        v-if="!item.type"
        :key="index"
        :prop="item.prop"
        :label="item.label"
        :width="item.width || '120'"
        show-overflow-tooltip
      ></el-table-column>

      <!-- but or other 使用方式
        scope.row.buttons = [
          {
            type: '', // button 样式
            name: '编辑', // button 名称
            func: '', // 回调方法
            component: '', // 类型
          },
        ]
       -->
      <el-table-column
        v-else-if="item.type === 'but' || item.type === 'other'"
        :key="index"
        :label="item.label"
        :width="item.width"
      >
        <template slot-scope="scope">
          <div class="buttons">
            <el-button
              v-for="(sub, i) in item.type === 'but'
                ? scope.row.buttons
                : scope.row.other"
              :key="`${index}-${i}`"
              size="mini"
              class="item-button"
              :type="sub.type"
              @click="handleEdit(scope.row, scope.$index, i, item.type)"
              >{{ sub.name }}</el-button
            >
          </div>
        </template>
      </el-table-column>

      <!--  tag使用方式
        item.tags = {
          type: item.supervisor === 1 ? 'success' : 'danger',
          name: item.supervisor === 1 ? '已启用' : '已禁用',
        }
       -->
      <el-table-column
        v-else-if="item.type === 'tag'"
        :key="index"
        :width="item.width"
        :label="item.label"
      >
        <template slot-scope="scope">
          <el-tag v-show='scope.row[item.prop].type' :type="scope.row[item.prop].type" disable-transitions>{{
           scope.row[item.prop].name
          }}</el-tag>
        </template>
      </el-table-column>

      <!-- img 使用方式
        item.imgs = item.files // [1.png, 2.png] or [{ url: 1.png }, { url: 2.png }]
      -->
      <el-table-column
        v-else-if="item.type === 'img'"
        :key="index"
        :label="item.label"
      >
        <template slot-scope="scope">
          <div class="imgs">
            <el-avatar
            v-for="(sub, i) in scope.row.imgs"
            :key="`${index}-${i}`"
            shape="square"
            :size="20"
            fit="fill"
            :src="sub.url || sub"
          ></el-avatar>
          </div>
        </template>
      </el-table-column>

      <!-- switch 使用方式
      item.prop = status // 字段名称

      // 把字段名称 改为 对象
      item.status = {
        status: item.status === 1 ? true : false // 开关
        disabled: true or false // 是否禁用
        func: '' // 方法回调
      }
       -->
      <el-table-column
        v-else-if="item.type === 'switch'"
        :key="index"
        :label="item.label"
        :width="item.width"
      >
        <template slot-scope="scope">
          <el-switch
            v-model="scope.row[item.prop || 'switch'].status"
            active-color="#13ce66"
            inactive-color="#ff4949"
            :disabled="scope.row[item.prop || 'switch'].disabled"
            @change="handleSwitch(scope.row, item.prop)"
            >
          </el-switch>
        </template>
      </el-table-column>

      <!-- range使用方式

        // 字段名称
        propSta: 'start_time',
        propEnd: 'end_time',

        item.propSta 开始
        item.propEnd 结束
       -->
      <el-table-column
        v-else-if="item.type === 'range'"
        :key="index"
        :label="item.label"
        :width="item.width"
      >
        <template slot-scope="scope">
          <div>{{scope.row[item.propSta]}}-{{scope.row[item.propEnd]}}</div>
        </template>
      </el-table-column>
    </template>
  </el-table>
</template>

<script>
export default {
  name: 'table',
  props: {
    isShowSele: {
      // 是否显示前面的选择框
      type: Boolean,
      default() {
        return true
      },
    },
    list: {
      // 表头列表
      type: Array,
      default() {
        return []
      },
    },
    data: {
      // 列表数据
      type: Array,
      default() {
        return []
      },
    },
    buttonChange: Function, // 操作方法
    selectChange: Function, // 选中方法
    switchChange: Function, // 切换方法
  },
  data() {
    return {}
  },
  updated() {
    this.$nextTick(() => {
      this.data.forEach(item => {
        if (item.select) {
          this.$refs.multipleTable.toggleRowSelection(item)
        }
      })
    })
  },
  methods: {
    handleSelectionChange: function (array) {
      const ids = []
      array.forEach((item) => {
        ids.push(item.id)
      })

      this.selectChange && this.selectChange(ids.join(','))
    },
    handleEdit: function (arr, index, i, type) {
      if (type === 'but') type = 'buttons'
      arr.show = arr[type][i].show || ''
      this.buttonChange && this.buttonChange(arr, arr[type][i].func, arr[type][i].component)
    },

    handleSwitch: function(curr, key = 'switch') {
      this.switchChange && this.switchChange(curr, curr[key].func)
    },

    // 清除全部选中
    clearSelection: function() {
      this.$refs.multipleTable.clearSelection()
    },
  },
}
</script>

<style lang="stylus" scoped>
.buttons {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  button {
    margin: 5px;
  }
}
.imgs {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  span {
    margin 5px;
  }
}
</style>
