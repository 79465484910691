<template>
      <el-pagination
      class="pages"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage4"
      :page-sizes="[10, 20, 30, 40]"
      :page-size="10"
      layout="total, sizes, prev, pager, next, jumper"
      :total="count">
    </el-pagination>
</template>

<script>
export default {
  name: 'page',
  props: {
    count: {
      type: Number,
      default() {
        return 0
      },
    },
    limitChange: Function, // 页码数量改变时 触发
    pageChange: Function, // 选择页码时 触发
  },
  data() {
    return {
      currentPage4: 1,
    }
  },
  methods: {
    handleCurrentChange: function(page) {
      this.pageChange && this.pageChange(page)
    },
    handleSizeChange: function(limit) {
      this.limitChange && this.limitChange(limit)
    },
  },
}
</script>

<style lang="stylus" scoped>
.pages{
  margin-top 20px;
  text-align end;
}
</style>
