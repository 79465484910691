import { PublicAxios } from '@/utils/publicAxios'
const $ = new PublicAxios()

// --------------------------------------------
// 工程API接口
// --------------------------------------------

/**
 * 获取工程列表
 * @param {Object} data 详看接口文档
 * Boolean => isLoad 是否需要加载框 默认为false
 */
export const getProject = async data => {
  return await $.get({
    url: 'admin/project/projectList',
    data,
    isLoad: true,
  })
}

/**
 * 删除工程列表
 * @param {String} ids 工程id
 */
export const delProject = async ids => {
  return await $.get({
    url: 'admin/project/projectDel',
    data: { ids },
  })
}

/**
 * 获取工程围栏列表
 * @param {Object} data 详看接口文档
 * Boolean => isLoad 是否需要加载框 默认为false
 */
export const getFence = async data => {
  return await $.get({
    url: 'admin/project/projectFenceList',
    data,
    isLoad: true,
  })
}

/**
 * 删除围栏
 * @param {String} ids 围栏id
 */
export const delFence = async ids => {
  return await $.get({
    url: 'admin/project/projectFenceDel',
    data: { ids },
  })
}

/**
 * 修改围栏信息
 * @param {Object} data 详看接口
 */
export const updFence = async data => {
  return await $.post({
    url: 'admin/project/projectFenceEdit',
    data,
  })
}

/**
 * 获取深基坑列表
 * @param {Object} data 详看接口文档
 * Boolean => isLoad 是否需要加载框 默认为false
 */
export const getDeep = async data => {
  return await $.get({
    url: 'admin/deeppit/deeppitList',
    data,
    isLoad: true,
  })
}

/**
 * 删除当前坑
 * @param {String} ids 坑 id
 */
export const delDeep = async ids => {
  return await $.get({
    url: 'admin/deeppit/deeppitDel',
    data: { ids },
  })
}

/**
 * 获取杆塔列表
 * @param {Object} data 详看接口文档
 * Boolean => isLoad 是否需要加载框 默认为false
 */
export const getTower = async data => {
  return await $.get({
    url: 'admin/tower/towerList',
    data,
    isLoad: true,
  })
}

/**
 * 删除杆塔
 * @param {String} ids 杆塔id
 */
export const delTower = async ids => {
  return await $.get({
    url: 'admin/tower/towerDel',
    data: { ids },
  })
}

/**
 * 获取到岗到位
 * @param {Object} data 详看接口文档
 * Boolean => isLoad 是否需要加载框 默认为false
 */
export const getDuty = async data => {
  return await $.get({
    url: 'admin/project/arriveList',
    data,
    isLoad: true,
  })
}

/**
 * 删除到岗到位记录
 * @param {String} ids 到岗到位 id
 */
export const delDuty = async ids => {
  return await $.get({
    url: 'admin/project/arriveDel',
    data: { ids },
  })
}

/**
 * 获取证件管理列表
 * @param {Object} data 详看接口
 * Boolean => isLoad 是否需要加载框 默认为false
 */
export const getId = async data => {
  return await $.get({
    url: 'admin/user/certList',
    data,
    isLoad: true,
  })
}

/**
 * 获取工作票列表
 * @param {Object} data 详看接口文档
 * Boolean => isLoad 是否需要加载框 默认为false
 */
export const getWorkTicket = async data => {
  return await $.get({
    url: 'admin/user/ticketList',
    data,
    isLoad: true,
  })
}

/**
 * 删除工作票
 * @param {String} ids 工作票id
 */
export const delWorkTicket = async ids => {
  return await $.get({
    url: 'admin/user/ticketDel',
    data: { ids },
  })
}
