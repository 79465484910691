<template>
  <div >
    <SEARCH :searchChange='onCallbackSearch' :list="searchArr"></SEARCH>
    <TABS :tabChange='onCallbackTab' :list="tabsArr"></TABS>
    <TABLE :selectChange='onCallbackSelect' :buttonChange='onCallbackButton' :list="tableArr" :data="dataArr"></TABLE>
    <PAGE :pageChange='onCallbackPage' :limitChange='onCallbackLimit' :count="count"></PAGE>
    <!-- <add-project ref='addP' :title="title"></add-project> -->
    <!-- <select-people ref='selectP' :title="title"></select-people> -->
  </div>
</template>

<script>
import SEARCH from '@/components/search' // 顶部搜索
import TABS from '@/components/tabs' // 横向 button
import TABLE from '@/components/table' // 列表
import PAGE from '@/components/page' // 分页
// import addProject from './components/addProject' // 添加或编辑工程
// import selectPeople from './components/selectPeople' // 绑定人员 或 授权人员
import { getProject, delProject } from '@/api/project'
export default {
  name: 'FaceRecLog',
  components: {
    SEARCH,
    TABS,
    TABLE,
    PAGE,
  },
  data() {
    return {
      searchArr: [
        {
          name: '姓名',
          type: 'input',
          options: [],
          key: 'name',
        },
        {
          name: '单位',
          type: 'picker',
          options: [],
          key: 'projectUnit',
        },
        {
          name: '人脸设备',
          type: 'picker',
          options: [],
          key: 'faceType',
        },
        {
          name: '刷脸时间',
          type: 'date',
          key: 'createTime',
        },
      ], // 搜索列表
      tabsArr: [
        {
          type: 'primary',
          name: '导出数据',
          func: '',
          isLoading: false,
        },
        {
          type: 'danger',
          name: '批量删除',
          func: 'removeProject',
          isLoading: false,

        },
      ], // 标签列表
      tableArr: [
        {
          prop: 'name',
          label: '姓名',
          width: '150',
        },
        {
          prop: 'serial',
          label: '单位',
          width: '150',
        },
        {
          prop: 'type',
          label: '分组',
          width: '150',
        },
        {
          prop: 'unit.unit',
          label: '手机号',
          width: '150',
        },
        {
          prop: 'unit.group',
          label: '刷脸设备',
          width: '150',
        },
        {
          prop: 'supervisor',
          label: '携戴手环',
          width: '150',
        },
        {
          prop: 'binddev',
          label: '携戴单兵',
          width: '150',
        },
        {
          prop: 'is_auth',
          label: '刷脸时间',
          width: '150',
        },
        {
          prop: '',
          label: '操作',
          width: '',
          type: 'but',
        },
      ], // 表头列表
      dataArr: [], // 数据列表
      butnArr: [
        {
          type: 'danger',
          name: '删除',
          isLoading: false,
          func: 'removeProject',
        },
      ], // 数据-操作列表
      count: 0, // 总页数
      formData: {
        page: 1,
        limit: 10,
        type: '',
        name: '',
        unit_id: '',
        start_at: '',
        end_at: '',
      },
      ids: '', // 要删除的id
      title: '添加工程', // 弹窗标题
      tabsIndex: 0, // tabs 按钮的索引
    }
  },
  created() {},
  mounted() {
    this._loadData()
  },
  methods: {
    _loadData: function () {
      this.formData.unit_id = this.$store.getters.USER_ID
      getProject(this.formData).then((res) => {
        const { result } = res

        this.count = result.count
        result.data.forEach((item) => {
          item.buttons = this.butnArr
          item.is_auth = item.is_auth ? '授权' : '未授权'
        })
        this.dataArr = result.data
      })
    },

    // 打开新增工程弹窗
    openAddProject: function() {
      this.loading()
      this.$refs.addP.open()
    },

    // 新增工程
    addProject: function() {

    },

    // 删除工程
    removeProject: function() {
      if (!this.ids) {
        this.$toast('请选择要删除的工程')
        this.loading()
        return false
      }
      delProject(this.ids).then(res => {
        this.$toast(res.message)
        this._loadData()
        this.loading()
      })
    },

    // 收到搜索回调
    onCallbackSearch: function(data) {
      // const { createTime, projectUnit, name, faceType } = data
      // this.formData.start_at = createTime[0]
      // this.formData.end_at = createTime[1]
      // this.formData.unit_id = projectUnit
      // this.formData.name = name
      // this.formData.type = faceType
      // this._loadData()
    },
    // 收到 tabs 回调
    onCallbackTab: function({ item, index }) {
      this.loading(index)
      this[item.func]()
    },

    // 打开或关闭tabs 按钮loading
    loading: function(index) {
      index && (this.tabsIndex = index)
      this.tabsArr[this.tabsIndex].isLoading = !this.tabsArr[this.tabsIndex].isLoading
    },

    // 收到 选择 回调
    onCallbackSelect: function(ids) {
      this.ids = ids
    },
    // 收到 表格 内方法 回调
    onCallbackButton: function(item, func) {
      this.ids = item.id
      this[func]()
    },
    // 收到改变页码回调
    onCallbackPage: function(page) {
      this.formData.page = page
      this._loadData()
    },
    // 收到改变一页数量回调
    onCallbackLimit: function(limit) {
      this.formData.limit = limit
      this._loadData()
    },
  },
}
</script>

<style lang="stylus" scoped></style>
